import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const ContentSection = ({ contentModuleId }) => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulLayoutContentSection {
                nodes {
                    id
                    heading
                    content {
                        childContentfulRichText {
                            html
                        }
                    }
                    topLinks
                }
            }
        }
    `);

    const content = data.allContentfulLayoutContentSection.nodes.find(edge => edge.id === contentModuleId);
    
    return (<div id="faq-section" className='section content-section bg-gray'>
        <div className="container mx-auto">
            <h2 className='section__title text-center mb-16 sal-animate' style={{ marginBottom: 40 }}>{ content.heading }</h2>
            <div>
                { content.topLinks.map((media, index) => media !== "#" && <iframe key={index} src={ media } style={{ width: '100%', height: "640px" }} allow="autoplay"></iframe>) }
                <div dangerouslySetInnerHTML={{ __html: content.content.childContentfulRichText.html }} />
            </div>
        </div>
    </div>)
};

export default ContentSection;