import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Service from 'components/service';

const AppFeatures = ({ contentModuleId }) => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulLayoutAppsFeatures {
                nodes {
                    id
                    heading
                    description {
                        description
                    }
                    serviceItems {
                        id
                        title
                        description {
                            description
                        }
                        image {
                            fluid(quality: 100, maxWidth: 208, maxHeight: 146) {
                                ...GatsbyContentfulFluid
                            }
                        }
                        url
                    }
                }
            }
        }
    `);

    const content = data.allContentfulLayoutAppsFeatures.nodes.find(edge => edge.id === contentModuleId);
    return (
        <section id="services" className="services app-features container section mx-auto">
            <div>
                <h2 className="section__title text-center mb-16 sal-animate" data-sal="fade" data-sal-easing="ease-in-cubic">{ content.heading }</h2>
                {
                    content.serviceItems.length > 0 &&
                    <div className="services__items">
                        {
                            content.serviceItems.map(service => (
                                <Service service={service} key={service.id} />
                            ))
                        }
                    </div>
                }
                
            </div>
        </section>
    );
};

AppFeatures.propTypes = {
    contentModuleId : PropTypes.string.isRequired
}

export default AppFeatures;