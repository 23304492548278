import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Glider from 'glider-js';

const SlidesShow = ({ contentModuleId }) => {

    const data = useStaticQuery(graphql`
        query {
            allContentfulLayoutSlideShow {
                edges {
                    node {
                        id
                        heading
                        slides {
                            id
                            title
                            image {
                              id
                              file {
                                url
                              }
                            }
                            description {
                              description
                            }
                        }
                    }
                }
            }
        }
    `);
    const slides = data.allContentfulLayoutSlideShow.edges.find(edge => edge.node.id === contentModuleId); 

    const initSlider = () => {
        new Glider(document.querySelector('.glider'),{
            slidesToShow: 1,
            dots: '.glider__dots',
            draggable: true,
            arrows: {
                prev: '.glider-prev',
                next: '.glider-next'
            }
        });
    }
   
    useEffect(() => {
        initSlider();
    });
   return (
    <section className="section slideShow">
        <div className='container mx-auto'>
            <h2 className="section__title text-center mb-16 sal-animate" data-sal="fade" data-sal-easing="ease-in-cubic">{ slides.node.heading }</h2>
            <div className="w-full pt-12 md:pt-0">
                {
                    slides.node.slides.length > 0 &&
                    <div className="testimonial__slider" data-sal="fade" data-sal-easing="ease-in-cubic">
                        <div className="glider">
                            {
                                slides.node.slides.map((slide, index) => (
                                    <div className="slide" key={ index }>
                                        <img src={ slide.image.file.url } />
                                        <strong>{ slide.title }</strong>
                                        <p>{ slide.description.description }</p>
                                    </div>
                                ))
                            }
                        </div>
                        <button className="glider-prev material-icons">keyboard_arrow_left</button>
                        <button className="glider-next material-icons">keyboard_arrow_right</button> 
                        {/* <div className="glider__dots"></div> */}
                    </div>
                }
            </div>
        </div>
    </section>
   );
};

SlidesShow.propTypes = {
    contentModuleId : PropTypes.string.isRequired
}

export default SlidesShow;