import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import FaqItem from './../components/faqItem';

const Faq = ({ contentModuleId }) => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulLayoutFaq {
                nodes {
                    id
                    heading
                    questions {
                        id
                        heading
                        content {
                            childContentfulRichText {
                                html
                            }
                        }
                    }
                }
            }
        }
    `);

    const content = data.allContentfulLayoutFaq.nodes.find(edge => edge.id === contentModuleId);

    return (<div id="faq-section" className='container section mx-auto'>
        <div className="w-100">
            <h2 className='section__title text-center mb-16 sal-animate' style={{ marginBottom: 40 }}>Frequently Asked Questions</h2>
            <div>
                {content.questions.map(({ heading, content }) => (
                <FaqItem title={heading} key={heading}>
                    <div dangerouslySetInnerHTML={{ __html: content.childContentfulRichText.html }} />
                </FaqItem>
                ))}
            </div>
        </div>
    </div>)
};

export default Faq;